/* eslint-disable no-unused-expressions */
import { numeric, minValue, maxValue } from 'vuelidate/lib/validators';

const companyValidationMixin = {
	validations: {
		cmsCompany: {
			priceMarginList: {
				$each: {
					priceMarginRate: {
						numeric,
						minValue: minValue(0),
						maxValue: maxValue(100),
					},
				},
			},
		},
	},
	methods: {
		// validation methods for this page
		priceMarginRateErrors(index) {
			const errors = [];
			// eslint-disable-next-line no-unused-vars
			const { $dirty, numeric, minValue, maxValue } = this.$v.cmsCompany.priceMarginList.$each[index].priceMarginRate;

			// if (!$dirty) return errors;

			!numeric && errors.push('Lütfen numerik bir değer giriniz');
			!minValue && errors.push('Minimum değer 0 olabilir.');
			!maxValue && errors.push('Maksimum değer 100 olabilir.');
			return errors;
		},
	},
};

export default companyValidationMixin;
