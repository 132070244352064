<template>
	<v-card flat v-if="initialized">
		<v-card-title> Firma Bilgileri </v-card-title>
		<v-card-text>
			<v-row dense>
				<v-col class="col-md-6 col-12">
					<v-text-field
						label="Firma Adı"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpName"
						readonly
						disabled
					></v-text-field>
				</v-col>
				<v-col class="col-md-6 col-12">
					<v-text-field
						label="URL"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpUrl"
						readonly
						disabled
					></v-text-field>
				</v-col>
				<v-col class="col-md-6 col-12">
					<v-text-field
						label="E-Mail"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpContactEMail"
						readonly
						disabled
					></v-text-field> </v-col
				><v-col class="col-md-6 col-12">
					<v-text-field
						label="Telefon"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpContactPhone"
						readonly
						disabled
					></v-text-field>
				</v-col>
				<v-col class="col-12">
					<v-textarea
						label="Adres"
						outlined
						hide-details="auto"
						flat
						full-width
						rows="2"
						no-resize
						v-model="settings.company.cmpContactAddress"
						readonly
						disabled
					></v-textarea>
				</v-col>
				<v-col class="col-md-6 col-12">
					<v-text-field
						label="Vergi Dairesi"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpTaxOffice"
						readonly
						disabled
					></v-text-field>
				</v-col>
				<v-col class="col-md-6 col-12">
					<v-text-field
						label="Vergi No"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpTaxNo"
						readonly
						disabled
					></v-text-field>
				</v-col>
				<v-col cols="12" md="3">
					<v-file-input
						hide-details="auto"
						outlined
						dense
						label="Aydınlatma metninizi içeren dosyayı seçin"
						prepend-icon=""
						clear-icon=""
						accept=".pdf"
						@change="changeContractFile"
					>
						<template v-slot:prepend-inner>
							<v-icon v-text="'fas fa-paperclip'" small class="mt-1 mr-1"></v-icon>
						</template>
					</v-file-input>
				</v-col>
				<v-col class="col-md-1 col-12 d-flex align-content-center align-center">
					<v-checkbox
						class="pa-0 mt-0"
						v-model="cmsCompany.isExchangeAdvantage"
						label="Takas Avantajı"
						hide-details="auto"
					></v-checkbox>
				</v-col>
				<v-col class="col-md-2 col-12 d-flex align-content-center align-center">
					<v-checkbox
						class="pa-0 mt-0"
						v-model="settings.company.cmpHasListing"
						label="Listeleme"
						hide-details="auto"
						disabled
					></v-checkbox>
				</v-col>
				<v-col class="col-md-2 col-12">
					<v-text-field
						label="Talep Zaman Aşım Süresi (Gün)"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="settings.company.cmpLeadTimeout"
					></v-text-field>
				</v-col>
				<v-col class="col-md-2 col-12">
					<v-text-field
						label="Günlük Fiyat Sorgulama Adedi"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="cmsCompany.dailyPriceQueryCount"
					></v-text-field>
				</v-col>
				<v-col class="col-md-2 col-12">
					<v-text-field
						label="Teklif Geçerlilik Süresi (Gün)"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="cmsCompany.offerValidityDay"
					></v-text-field>
				</v-col>
				<v-col class="col-md-2 col-12 d-flex align-content-center align-center">
					<v-checkbox
						class="pa-0 mt-0"
						v-model="cmsCompany.isWeeklyCarInsight"
						label="Haftalık Araç Trendi"
						hide-details="auto"
					></v-checkbox>
				</v-col>
				<v-col class="col-md-4 col-12">
					<v-text-field
						label="Haftalık Araç Trendi Listelenecek Ay Sayısı"
						outlined
						hide-details="auto"
						flat
						dense
						v-model="cmsCompany.monthlyCarInsightCount"
					></v-text-field>
				</v-col>
				<v-col class="col-md-6 col-12"> </v-col>
				<v-col v-if="settings.priceMarginList.length > 0">
					<v-card flat>
						<v-card-title class="px-0"> SuperApp teklif fiyat marjı </v-card-title>
						<v-card-text>
							<v-list dense>
								<v-list-item
									dense
									class="px-0"
									v-for="(priceMargin, index) in $v.cmsCompany.priceMarginList.$each.$iter"
									:key="`priceMargin-${index}`"
								>
									<v-list-item-content>
										<v-row align="center">
											<v-col cols="12" md="2">
												{{ priceMargin.$model.companyName }}
											</v-col>
											<v-col cols="12" md="2">
												<v-text-field-percent
													v-model.number="priceMargin.$model.priceMarginRate"
													label="Oran %"
													v-bind:properties="{
														suffix: '%',
														outlined: false,
														placeholder: 'Oran',
														outlined: true,
														dense: true,
														hideDetails: 'auto',
														errorMessages: priceMarginRateErrors(index),
													}"
													v-bind:options="{
														locale: 'tr-TR',
														length: 3,
														precision: 0,
														empty: 0,
													}"
												/>
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-if="false">
				<v-col cols="12" md="3">
					<v-card-title class="px-0">Adres Bilgileri</v-card-title>
					<v-row dense>
						<v-col cols="12">
							<v-select
								outlined
								label="Şehir seçiniz"
								item-text="citName"
								item-value="citId"
								:items="cities"
								:return-object="false"
								hide-details="auto"
								dense
								color="teal"
							></v-select>
						</v-col>
						<v-col cols="12">
							<v-textarea
								hide-details="auto"
								outlined
								full-width
								rows="5"
								no-resize
								label="Açık Adres Giriniz.."
								color="teal"
							></v-textarea>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="12" md="9">
					<div id="gmap" style="width: 100%; height: 400px"></div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					{{ this.cmsCompany.mapLongitude }}
					<google-maps
						v-if="initialized"
						:longitude.sync="cmsCompany.mapLongitude"
						:latitude.sync="cmsCompany.mapLatitude"
						:address="settings.company.cmpAddress"
					/>
				</v-col>
			</v-row>
			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsCompany.$invalid"
					@click="save"
					:disabled="$v.cmsCompany.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { Loader } from 'google-maps';
import { FETCH_SITE_SETTINGS, UPDATE_COMPANY_INFO } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import companyValidationMixin from '@/mixins/validations/backoffice/companyValidationMixin';
import GoogleMaps from './components/GoogleMaps.vue';

export default {
	name: 'Site',
	components: {
		GoogleMaps,
	},
	mixins: [validationMixin, companyValidationMixin],
	data() {
		return {
			map: null,
			marker: null,
			loader: null,
			savePreloader: false,
			cmsCompany: {
				isExchangeAdvantage: false,
				mapLongitude: null,
				mapLatitude: null,
				endUserContractFile: null,
				endUserContractFileByte: null,
				endUserContractFileType: null,
				leadTimeout: null,
				priceMarginList: null,
				dailyPriceQueryCount: null,
				offerValidityDay: null,
				isWeeklyCarInsight: false,
				monthlyCarInsightCount: null,
			},
		};
	},
	computed: {
		...mapGetters(['settings']),
		initialized() {
			return this.cmsSiteSettings !== null;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings().then(() => {
				const mapLatitude = parseFloat(this.cmsCompany.mapLatitude || 40.98508293243896);
				const mapLongitude = parseFloat(this.cmsCompany.mapLongitude || 29.05122767088152);
				// this.initMap();

				this.cmsCompany.mapLongitude = mapLongitude.toString();
				this.cmsCompany.mapLatitude = mapLatitude.toString();
			});
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateCompany: UPDATE_COMPANY_INFO,
		}),

		initMap() {
			if (this.loader === null) this.loader = new Loader('AIzaSyAwy8YmSr5XYcbn7syiuAzojGsjPnS4zII', {});

			this.loader.load().then((google) => {
				let mapLatitude = parseFloat(this.cmsCompany.mapLatitude || 40.98508293243896);
				let mapLongitude = parseFloat(this.cmsCompany.mapLongitude || 29.05122767088152);

				this.map = new google.maps.Map(document.getElementById('gmap'), {
					center: { lat: mapLatitude, lng: mapLongitude },
					zoom: 14,
					zoomControl: true,
				});

				this.marker = new google.maps.Marker({
					position: { lat: mapLatitude, lng: mapLongitude },
					draggable: true,
					map: this.map,
				});

				google.maps.event.addListener(this.marker, 'dragend', (event) => {
					this.marker.getPosition();
					mapLatitude = event.latLng.lat();
					mapLongitude = event.latLng.lng();

					this.cmsCompany.mapLongitude = mapLongitude;
					this.cmsCompany.mapLatitude = mapLatitude;
				});
			});
		},
		checkFileExtension(files) {
			return new Promise((resolve, reject) => {
				const allowedExtensions = /(\.pdf)$/i;

				const hasIgnoredExt = Array.from(files).some((f) => !allowedExtensions.exec(f?.name));

				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}
				resolve();
			});
		},
		async changeContractFile(e) {
			const data = e;
			this.checkFileExtension([data]).then(async () => {
				if (data) {
					const response = await this.getFile(data);
					this.cmsCompany.endUserContractFileByte = response.file;
					this.cmsCompany.endUserContractFile = response.name;
				}
			});
		},
		getFile(file) {
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					const array = new Uint8Array(e.target.result);
					const byte = [];
					for (let i = 0; i < array.length; i += 1) {
						byte.push(array[i]);
					}
					resolve({ name: file.name, file: byte });
				};
				reader.readAsArrayBuffer(file);
			});
		},
		save() {
			this.savePreloader = true;
			this.updateCompany(this.cmsCompany)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsCompany = {
					isExchangeAdvantage: company.cmpExchangeAdvantage,
					mapLongitude: company.cmpMapLongitude,
					mapLatitude: company.cmpMapLatitude,
					endUserContractFile: null,
					endUserContractFileByte: null,
					endUserContractFileType: null,
					leadTimeout: company.cmpLeadTimeout,
					priceMarginList: this.settings.priceMarginList,
					dailyPriceQueryCount: company.cmpDailyPriceQueryCount,
					offerValidityDay: company.cmpOfferValidityDay,
					isWeeklyCarInsight: company.cmpIsWeeklyCarInsight,
					monthlyCarInsightCount: company.cmpMonthlyCarInsightCount,
				};
			},
			deep: true,
		},
	},
};
</script>

<style></style>
