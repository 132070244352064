<template>
	<div>
		<v-textarea label="Adres" rows="3" no-resize hide-details="auto" outlined full-width solo v-model="addressInput" />
		<div class="mt-5" id="map" ref="map" style="height: 500px; width: 100%"></div>
	</div>
</template>
<script>
// eslint-disable-next-line no-undef
const geocoder = new google.maps.Geocoder();

export default {
	name: 'GoogleMaps',
	props: {
		longitude: {
			type: String,
		},
		latitude: {
			type: String,
		},
		address: {
			type: String,
		},
	},
	data() {
		return {
			map: null,
			marker: null,
			addressInput: this.address,
			longitudeInput: this.longitude,
			latitudeInput: this.latitude,
		};
	},

	mounted() {
		this.$nextTick(() => {
			this.addressInput = this.address;
			setTimeout(() => {
				this.initMap();
			}, 1000);
		});
	},

	methods: {
		initMap() {
			this.longitudeInput = Number(this.longitude);
			this.latitudeInput = Number(this.latitude);

			const latitute = Number(this.latitude);
			const longitude = Number(this.longitude);
			// eslint-disable-next-line no-undef
			this.map = new google.maps.Map(this.$refs.map, {
				zoom: 17,
				center: { lat: latitute, lng: longitude },
			});

			// eslint-disable-next-line no-undef
			this.marker = new google.maps.Marker({
				position: { lat: latitute, lng: longitude },
				map: this.map,
				draggable: true,
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListener(this.map, 'dragend', () => {
				this.marker.setPosition(this.map.getCenter());
				const newAddress = this.getAddress(this.marker.getPosition());
				this.addressInput = newAddress;
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListener(this.marker, 'dragend', () => {
				this.getAddress();
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListener(map, 'zoom_changed', () => {
				this.marker.setPosition(this.map.getCenter());
			});

			this.$watch('addressInput', this.$debounce(this.setMap, 500, { trailing: true }));
		},
		setMap() {
			geocoder.geocode({ address: this.addressInput }, (results, status) => {
				if (status === 'OK') {
					const { lat, lng } = results[0].geometry.location;

					const latitute = lat();
					const longitude = lng();

					this.map.setCenter({ lat: latitute, lng: longitude });
					this.marker.setPosition({ lat: latitute, lng: longitude });

					this.latitudeInput = latitute;
					this.longitudeInput = longitude;

					this.updatePropValues();
				}
			});
		},
		getAddress() {
			const position = this.marker.getPosition();
			const lat = position.lat();
			const lng = position.lng();
			const latlng = { lat, lng };

			this.latitudeInput = lat;
			this.longitudeInput = lng;

			geocoder.geocode({ location: latlng }, (results, status) => {
				if (status === 'OK') {
					const address = results[0].formatted_address;
					this.addressInput = address;
				}
			});
		},
		updatePropValues() {
			this.$emit('update:latitude', this.latitudeInput.toString());
			this.$emit('update:longitude', this.longitudeInput.toString());
		},
	},
};
</script>
